import * as R from 'ramda'
import {graphql} from 'gatsby'
import {pageContextPropTypes} from 'helpers/propTypes'
import {shape} from 'prop-types'
import React from 'react'

import {clearHtmlAndshrink, stringToSlug} from 'helpers/utils'
import Box from 'components/UI/Box'
import FlexBoxColumnsLayout from 'components/UI/Layouts/FlexBoxColumnsLayout'
import HeroRichText from 'components/UI/HeroRichText'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import SimpleCard from 'components/CommittedEmployees/Card'
import Title from 'components/UI/Title'

const CommittedEmployees = ({data, pageContext}) => {
  const countryData = R.path(['contentfulCountry'], data)

  const {
    metaTitle,
    metaDescription,
    image,
    title,
    shortDescription,
    mainTitle,
    box,
    projects,
  } = R.pathOr(null, ['allContentfulCommittedEmployees', 'nodes', 0], data)

  const topProjects = R.slice(0, 3, projects)
  const subProjects = R.slice(3, projects.length, projects)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <HeroRichText
          title={title}
          titleType="title"
          description={shortDescription}
          descriptionType="description"
          media={image}
          hasAutoWidth
        />
      </Section>
      <Section hasPaddingBottom={false} hasSmallPadding>
        <Title variant="h2" type="subTitle">
          {mainTitle}
        </Title>
        <FlexBoxColumnsLayout>
          {R.map(
            project => (
              <SimpleCard
                id={stringToSlug(project.primaryText.primaryText)}
                title={project.primaryText.primaryText}
                secondaryText={project.secondaryText.secondaryText}
                description={project.tertiaryText.tertiaryText}
                image={project.image}
              />
            ),
            topProjects,
          )}
        </FlexBoxColumnsLayout>
      </Section>
      <Section hasSmallPadding>
        <Box isCta isWide box={box} />
      </Section>
      <Section hasPaddingTop={false}>
        <FlexBoxColumnsLayout>
          {R.map(
            project => (
              <SimpleCard
                id={stringToSlug(project.primaryText.primaryText)}
                title={project.primaryText.primaryText}
                secondaryText={project.secondaryText.secondaryText}
                description={project.tertiaryText.tertiaryText}
                image={project.image}
              />
            ),
            subProjects,
          )}
        </FlexBoxColumnsLayout>
      </Section>
    </>
  )
}

CommittedEmployees.propTypes = {
  data: shape({}).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

export const pageQuery = graphql`
  query templateCommittedEmployees(
    $nodeLocale: String
    $technicalName: String
    $entityRegEx: String
  ) {
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      id
      name
      ...countryDataFields
      salesforce
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    allContentfulCommittedEmployees(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      nodes {
        ...committedEmployeesFields
      }
    }
  }
`

export default CommittedEmployees
